<template>
  <div class="primary--text">
    <div class="text-h4 font-weight-bold">
      {{ stat }}
    </div>
    <div class="text-h6 font-weight-medium">
      {{ title }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      stat: {
        type: [Number, String],
        default: 0,
      },
      title: {
        type: String,
        default: '',
      },
    },
  }
</script>

<style>

</style>
